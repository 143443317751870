import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

export function getFormattedDate(
    unixTimestamp: number,
    t: ReturnType<typeof useI18next>['t']
): string {
    const date = new Date(unixTimestamp * 1000);
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    return `${t(`month.${monthIndex}`)} ${year}`;
}

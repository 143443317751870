import React from 'react';

import {
    container,
    content,
    listWork,
    gridContent,
    item,
    ratio,
    header,
    card,
} from './work-grid.module.scss';

import { grid } from '../../styles/grid.module.scss';
import { IWork } from '../../models/work.model';
import Title, { ITitleProps } from '../atoms/title';
import Button, { TButtonProps } from '../atoms/button';
import WorkItem from '../molecules/work-item';

interface IWorkGridProps {
    className?: string;
    title?: string;
    titleProps?: Omit<ITitleProps, 'Tag' | 'size'>;
    buttonText?: string;
    buttonProps?: TButtonProps;
    works: IWork[];
    id?: string;
}

const WorkGrid: React.FC<IWorkGridProps> = ({
    className = '',
    title,
    titleProps,
    buttonText,
    buttonProps,
    works,
    id,
}) => {
    return (
        <div className={`${className} ${grid} ${container}`} {...(id ? { id } : {})}>
            <div className={content}>
                {title && (
                    <Title {...titleProps} className={header}>
                        {title}
                    </Title>
                )}

                <ul className={`${gridContent}  ${listWork}`}>
                    {works.map((work) => {
                        return (
                            <li key={work.workId} className={` ${ratio} ${item} `}>
                                <WorkItem className={card} work={work} />
                            </li>
                        );
                    })}
                </ul>

                {buttonText && (
                    <Button
                        {...buttonProps}
                        className={` ${buttonProps?.className ? buttonProps.className : ''}`}
                    >
                        {buttonText}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default WorkGrid;

import React from 'react';

import { container, list, item, header, content, button, card } from './post-list.module.scss';
import { grid } from '../../styles/grid.module.scss';
import { IPostCard } from '../../models/post.model';

import Button, { TButtonProps } from '../atoms/button';
import PostCard, { IPostCardProps } from '../molecules/post-card';
import Title, { ITitleProps } from '../atoms/title';

interface INewsListProps {
    className?: string;
    id?: string;
    posts: IPostCard[];
    title?: string;
    titleProps?: Omit<ITitleProps, 'className' | 'children'>;
    cardProps?: Pick<IPostCardProps, 'TitleTag' | 'LeadTag'>;
    showButton?: boolean;
    buttonText?: string;
    buttonProps?: TButtonProps;
}

const PostList: React.FC<INewsListProps> = ({
    className = '',
    id,
    posts,
    title,
    titleProps,
    cardProps,
    showButton = true,
    buttonText,
    buttonProps = {},
}) => {
    return (
        <div className={`${container} ${grid} ${className}`} {...(id ? { id } : {})}>
            <div className={content}>
                {title && (
                    <Title className={header} {...titleProps}>
                        {title}
                    </Title>
                )}
                <ul className={list}>
                    {posts.map((post) => {
                        return (
                            <li key={post.newsId} className={item}>
                                <PostCard postCard={post} className={card} {...cardProps} />
                            </li>
                        );
                    })}
                </ul>
                {showButton && buttonText && (
                    <Button
                        {...buttonProps}
                        className={`${button} ${
                            buttonProps?.className ? buttonProps.className : ''
                        }`}
                    >
                        {buttonText}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default PostList;

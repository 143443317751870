import React from 'react';

import { container } from './post-meta.module.scss';
import { translationKeys } from '../../config/translation-keys';
import { IPost, IPostCard } from '../../models/post.model';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { useTranslation } from '../../hooks/use-translation';
import { getFormattedDate } from '../../utils/get-formatted-date';

interface IPostMetaProps {
    className?: string;
    post: IPost | IPostCard;
}

const PostMeta: React.FC<IPostMetaProps> = ({ className, post }) => {
    const { t } = useI18next();
    const { author, publishedAt } = post;
    const { firstName, lastName } = useTranslation(author, translationKeys.author);

    const name = firstName && lastName && `${firstName} ${lastName}`;
    const date = getFormattedDate(publishedAt, t);

    return (
        <div className={`${container} ${className}`}>
            {date}
            {name ? ` / ${name}` : ''}
        </div>
    );
};

export default PostMeta;

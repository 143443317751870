import React from 'react';
import { graphql } from 'gatsby';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { layout, banner, about, videoEye, whyUs, news, workList } from './index.module.scss';
import pageContexts from '../config/page-contexts';
import { navLinks } from '../config/nav-links';
import { IQueryAllResult } from '../models/query-all-result.model';
import { IPostCard } from '../models/post.model';
import { IWork } from '../models/work.model';
import { getNodes } from '../utils/get-nodes';

import MainLayout from '../layouts/main-layout';
import AboutUs from '../components/organisms/about-us';
import HomeVideoBanner from '../components/organisms/home-video-banner';
import Contact from '../components/organisms/contact';
import WhyUs from '../components/organisms/why-us';
import PostList from '../components/organisms/post-list';
import WorkGrid from '../components/organisms/work-grid';

interface IIndexPageProps {
    readonly data: {
        allPost: IQueryAllResult<IPostCard>;
        allWork: IQueryAllResult<IWork>;
    };
}

const IndexPage: React.FC<IIndexPageProps> = ({ data }) => {
    const { allPost, allWork } = data;
    const works = getNodes(allWork);
    const posts = getNodes(allPost);
    const { t } = useI18next();

    return (
        <MainLayout isVisibleHeaderBg={false} className={layout}>
            <HomeVideoBanner className={banner} />
            {works.length > 0 && (
                <WorkGrid
                    className={workList}
                    id={t(navLinks.works.urlIdKey)}
                    title={t('work.grid.title')}
                    buttonText={t('work.grid.button')}
                    buttonProps={{
                        as: 'link',
                        to: pageContexts.works.slug,
                    }}
                    works={works}
                />
            )}
            <WhyUs className={whyUs} id={t(navLinks.whyUs.urlIdKey)} />
            {posts.length > 0 && (
                <PostList
                    className={news}
                    id={t(navLinks.news.urlIdKey)}
                    title={t('home.news.title')}
                    cardProps={{ TitleTag: 'h3', LeadTag: 'h4' }}
                    buttonText={t('home.news.button')}
                    buttonProps={{
                        as: 'link',
                        to: pageContexts.blog.slug,
                    }}
                    posts={posts}
                />
            )}
            <AboutUs className={about} id={t(navLinks.about.urlIdKey)} />
            <Contact className={videoEye} id={t(navLinks.contact.urlIdKey)} />
        </MainLayout>
    );
};

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        allPost(limit: 4, sort: { fields: publishedAt, order: DESC }) {
            edges {
                node {
                    ...postCardFields
                }
            }
        }

        allWork(limit: 6, sort: { fields: publishedAt, order: DESC }) {
            edges {
                node {
                    ...workFields
                }
            }
        }
    }
`;

export default IndexPage;

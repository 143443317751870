// extracted by mini-css-extract-plugin
export var contentLink = "work-item-module--content-link--sPnrc";
export var transformText = "work-item-module--transform-text--SR0g8";
export var videoTopText = "work-item-module--video-top-text--ZaHUE";
export var workBox = "work-item-module--work-box--NeWSE";
export var darkBg = "work-item-module--dark-bg--YUD8B";
export var workContainer = "work-item-module--work-container--vE4ab";
export var player = "work-item-module--player--rMGax";
export var image = "work-item-module--image--DOAPL";
export var videoBottomText = "work-item-module--video-bottom-text--qaKJm";
export var textMobile = "work-item-module--text-mobile--kAsd0";
export var textDesktop = "work-item-module--text-desktop--gm81V";
import React, { useRef } from 'react';
import Link from '../../../plugins/gatsby-plugin-ap-i18next/src/link';
import { GatsbyImage } from 'gatsby-plugin-image';

import {
    workBox,
    workContainer,
    player,
    videoTopText,
    videoBottomText,
    contentLink,
    textMobile,
    textDesktop,
    darkBg,
    transformText,
    image,
} from './work-item.module.scss';
import { relations } from '../../config/relations';
import { translationKeys } from '../../config/translation-keys';
import { IWorkCard } from '../../models/work.model';
import { useTranslation } from '../../hooks/use-translation';
import useOrientation from '../../hooks/use-orientation';
import useMedia from '../../hooks/use-media';
import { isMobileDevice } from '../../utils/is-mobile-device';
import { getMediaWithRelation } from '../../utils/get-media-with-relation';

interface IWorkItemProps {
    className?: string;
    work: IWorkCard;
}

const WorkItem: React.FC<IWorkItemProps> = ({ className = '', work }) => {
    const isMobile = isMobileDevice();
    const containerRef = useRef<HTMLDivElement>(null);
    const videoRef = useRef<HTMLVideoElement>(null);
    const orientation = useOrientation(containerRef);

    const { media, slug } = work;

    const videoVertical = getMediaWithRelation(
        media,
        relations.videoVertical,
        true,
        relations.thumbnailVertical
    );

    const videoHorizontal = getMediaWithRelation(
        media,
        relations.videoHorizontal,
        true,
        relations.thumbnailHorizontal
    );

    const thumbnailVertical = useMedia({ media, relation: relations.thumbnailVertical });
    const thumbnailHorizontal = useMedia({ media, relation: relations.thumbnailHorizontal });

    const isVideoVertical = videoVertical?.relations[0] === relations.videoVertical;
    const isVideoHorizontal = videoHorizontal?.relations[0] === relations.videoHorizontal;

    const { title, clientContent } = useTranslation(work, translationKeys.workCard);

    const handleOnMouseEnter = () => {
        const video = videoRef.current;
        if (!video) return;

        const isPlaying =
            video.currentTime > 0 &&
            !video.paused &&
            !video.ended &&
            video.readyState > video.HAVE_CURRENT_DATA;

        if (!isPlaying) {
            video.play();
        }
    };

    const handleOnMouseLeave = () => {
        if (!videoRef.current) return;
        videoRef.current.pause();
    };

    return (
        <Link to={slug} className={`${className} ${contentLink} ${!isMobile ? transformText : ''}`}>
            <div ref={containerRef} className={`${workBox} ${isMobile ? darkBg : ''} `}>
                <div className={workContainer}>
                    {!isMobile &&
                        orientation === 'horizontal' &&
                        isVideoHorizontal &&
                        videoHorizontal?.url && (
                            <video
                                key="horizontal"
                                className={player}
                                muted={true}
                                playsInline={true}
                                loop={true}
                                ref={videoRef}
                                onMouseEnter={handleOnMouseEnter}
                                onMouseLeave={handleOnMouseLeave}
                            >
                                <source src={videoHorizontal.url} type="video/mp4" />
                            </video>
                        )}
                    {!isMobile &&
                        orientation === 'vertical' &&
                        isVideoVertical &&
                        videoVertical?.url && (
                            <video
                                key="vertical"
                                className={player}
                                muted={true}
                                playsInline={true}
                                loop={true}
                                ref={videoRef}
                                onMouseEnter={handleOnMouseEnter}
                                onMouseLeave={handleOnMouseLeave}
                            >
                                <source src={videoVertical.url} type="video/mp4" />
                            </video>
                        )}

                    {orientation === 'horizontal' && isMobile && thumbnailHorizontal.image && (
                        <GatsbyImage
                            className={image}
                            image={thumbnailHorizontal.image}
                            alt={thumbnailHorizontal.alt || ''}
                        />
                    )}
                    {orientation === 'vertical' && isMobile && thumbnailVertical.image && (
                        <GatsbyImage
                            className={image}
                            image={thumbnailVertical.image}
                            alt={thumbnailVertical.alt || ''}
                        />
                    )}
                </div>
            </div>
            <h3 className={`${videoTopText} ${isMobile ? textMobile : textDesktop}`}>{title}</h3>
            <p className={videoBottomText}>{clientContent}</p>
        </Link>
    );
};

export default WorkItem;

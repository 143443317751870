import { RefObject, useEffect, useState } from 'react';

import useWindowWidth from './use-window-width';

type TOrientation = 'horizontal' | 'vertical';

const useOrientation = (ref: RefObject<HTMLElement>) => {
    const windowWidth = useWindowWidth();

    const [orientation, setOrientation] = useState<TOrientation>('horizontal');

    useEffect(() => {
        if (!ref.current) return;

        const width = ref.current.offsetWidth;
        const height = ref.current.offsetHeight;

        if (width > height) {
            setOrientation('horizontal');
        } else {
            setOrientation('vertical');
        }
    }, [windowWidth]);

    return orientation;
};

export default useOrientation;

import React from 'react';
import Link from '../../../plugins/gatsby-plugin-ap-i18next/src/link';

import {
    container,
    image,
    ratio,
    content,
    header,
    titleText,
    leadText,
} from './post-card.module.scss';
import { translationKeys } from '../../config/translation-keys';
import { relations } from '../../config/relations';
import { IPostCard } from '../../models/post.model';
import { useTranslation } from '../../hooks/use-translation';
import useMedia from '../../hooks/use-media';

import RatioImage from '../atoms/ratio-image';
import PostMeta from '../atoms/post-meta';

export interface IPostCardProps {
    className?: string;
    postCard: IPostCard;
    TitleTag?: React.ElementType;
    LeadTag?: React.ElementType;
}

const PostCard: React.FC<IPostCardProps> = ({
    className = '',
    postCard,
    TitleTag = 'h2',
    LeadTag = 'h3',
}) => {
    const { media, slug } = postCard;
    const { title, lead } = useTranslation(postCard, translationKeys.postCard);
    const mediaItem = useMedia({ media, relation: relations.coverImage });

    return (
        <Link to={slug} className={`${container} ${className}`}>
            <RatioImage
                image={mediaItem.image}
                alt={mediaItem.alt}
                fallbackUrl={mediaItem.url}
                className={image}
                ratioClass={ratio}
            />
            <div className={content}>
                <div className={header}>
                    <TitleTag className={titleText}>{title}</TitleTag>
                    {lead && <LeadTag className={leadText}>{lead}</LeadTag>}
                </div>
                <PostMeta post={postCard} />
            </div>
        </Link>
    );
};

export default PostCard;

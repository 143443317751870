const { POST_PAGE_COUNT } = require('./consts');
module.exports = {
    home: {
        slug: '/',
        translations: {
            pl: {
                label: 'Strona główna',
                slug: '/',
            },
            en: {
                label: 'Home',
                slug: '/',
            },
        },
    },
    blog: {
        slug: 'news',
        limit: POST_PAGE_COUNT,
        translations: {
            pl: {
                label: 'News',
                slug: 'news',
            },
            en: {
                label: 'News',
                slug: 'news',
            },
        },
    },
    works: {
        slug: 'projekty',
        translations: {
            pl: {
                label: 'Projekty',
                slug: 'projekty',
            },
            en: {
                label: 'Works',
                slug: 'works',
            },
        },
    },
    landing: {
        slug: 'credential',
        translations: {
            pl: {
                label: 'Credential',
                slug: 'credential',
            },
            en: {
                label: 'Credential',
                slug: 'credential',
            },
        },
    },
};
